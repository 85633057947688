import { type NavbarConfigFragment, type NavbarConfigSectionFragment } from "@gql-schema";
import { SeoSubmenu } from "@shared/components/Navigation/elements/SeoSubmenu";
import { NavBarMenuButton, NavBarMenuButtonVariant } from "@ui/components/NavBar/NavBarMenuButton";
import { NavBarMenuButtonBlock } from "@ui/components/NavBar/styles/NavBarMenuButtonBlock";
import { useBooleanWithRouteTransition } from "@ui/hooks/useBooleanWithRouteTransition";
import { COLORS } from "@ui/theme/colors";
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { usePopUpDataAtom, useResetPopUpDataAtom } from "./popUpDataAtom";

interface DesktopLandingPageMenuItemProps {
    activeMenuItemIndex?: number;
    data: NavbarConfigFragment | undefined;
    index: number;
    item: NavbarConfigSectionFragment;
    variant?: NavBarMenuButtonVariant;
    isActive: boolean;
}

export const DesktopLandingPageMenuItem: FC<PropsWithChildren<DesktopLandingPageMenuItemProps>> = (props) => {
    const { variant, data, item } = props;

    const [isOpen, setIsOpen] = useBooleanWithRouteTransition(false);
    const [dataKey, setDataKey] = usePopUpDataAtom();
    const resetDataKey = useResetPopUpDataAtom();

    const openPopUp = useCallback(() => {
        setDataKey(props.index);
        setIsOpen(true);
    }, [setDataKey, props.index, setIsOpen]);

    const _hasSubmenu = item.columns.length > 0;

    const isOpenStyle = useMemo(
        () =>
            isOpen && _hasSubmenu
                ? { backgroundColor: variant === "default" ? COLORS.green.secondary : COLORS.white }
                : undefined,
        [_hasSubmenu, isOpen, variant],
    );

    useEffect(() => {
        if (props.index !== dataKey) {
            setIsOpen(false);
        }
    }, [dataKey, props.index, setIsOpen]);

    return (
        <NavBarMenuButtonBlock onMouseEnter={openPopUp} onMouseOver={openPopUp} style={isOpenStyle}>
            <NavBarMenuButton
                hasSubmenu={_hasSubmenu}
                isActive={props.isActive}
                onClick={resetDataKey}
                variant={isOpen && _hasSubmenu ? (variant === "default" ? "default" : "black") : variant}
                title={item.label}
                href={item.href}
            />
            {data && data.sections[props.index] && <SeoSubmenu data={data.sections[props.index]} />}
        </NavBarMenuButtonBlock>
    );
};
