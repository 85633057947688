import { getUrlByRoute, useRouter } from "@app-routes";
import { type NavbarConfigFragment } from "@gql-schema";
import { NavBarMenuButton, NavBarMenuButtonVariant } from "@ui/components/NavBar/NavBarMenuButton";
import { NavBarMenuButtonBlock } from "@ui/components/NavBar/styles/NavBarMenuButtonBlock";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { FC, PropsWithChildren, memo } from "react";
import { DesktopLandingPageMenuItem } from "./DesktopLandingPageMenuItem";
import { DesktopPopUp } from "./DesktopPopUp";
import { usePopUpDataAtom } from "./popUpDataAtom";

interface DesktopLandingPageMenuProps {
    activeMenuItemIndex?: number;
    variant?: NavBarMenuButtonVariant;
    navbarData: NavbarConfigFragment;
}

const DesktopLandingPageMenuComponent: FC<PropsWithChildren<DesktopLandingPageMenuProps>> = (props) => {
    const { navbarData, activeMenuItemIndex, variant = "default" } = props;

    const [dataKey] = usePopUpDataAtom();

    const router = useRouter();

    const popUpData = isNotNil(dataKey) ? navbarData.sections[dataKey] : null;

    return (
        <>
            {navbarData.sections.map((item, index) => (
                <DesktopLandingPageMenuItem
                    isActive={index === activeMenuItemIndex}
                    data={navbarData}
                    index={index}
                    item={item}
                    key={index}
                    variant={variant}
                />
            ))}
            <NavBarMenuButtonBlock>
                <NavBarMenuButton
                    hasSubmenu={false}
                    isActive={router.route === "watch-wolf-zone/entry-page"}
                    variant={variant}
                    title="Hlídací vlk"
                    href={getUrlByRoute("watch-wolf-zone/entry-page")}
                    $fontWeight={500}
                />
            </NavBarMenuButtonBlock>
            {popUpData && <DesktopPopUp border={variant === "default"} data={popUpData} />}
        </>
    );
};

export const DesktopLandingPageMenu = memo(DesktopLandingPageMenuComponent);

DesktopLandingPageMenu.displayName = "DesktopLandingPageMenu";
